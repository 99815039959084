.a-tag{
    background: $bg-primary;
    font-size: 1.3rem;
    color: $text-white;
    height: 24px;
    padding: 0 10px 0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border-radius: $border-radius-primary;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    margin-left: 0px;


}
.a-tag.-sale{
    background: $bg-red;


}
.a-tag.-new{
    background: $bg-green;


}
.a-tag.-recommended{
    background: $bg-primary;

}
.a-tag.-top{
    background: $bg-yellow;

}
