.o-footer{
    background: $bg-brand-dark;
}
.m-emailSubscribe{
    padding: 0;
}
.m-emailSubscribe__title{
    font-size: 1.8rem;
    font-weight: 600;
    color: $text-white;
    margin-bottom: 3rem;
    margin-top: 0rem;
}
.m-emailSubscribe__text{
    @include f-16-white-regular;
    margin-bottom: 3rem;
}
.m-emailSubscribe__inputWrap{
    position: relative;
    max-width: 520px;
}
.m-emailSubscribe__input{
    height: 5.4rem;
    width: 100%;
}
.m-emailSubscribe__btn{
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    min-height: 5rem;
    height: auto;
}
.m-emailSubscribe__agree{
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.m-emailSubscribe__inlineText{
    @include f-14-white-regular;
    font-size: 1.3rem;

    a{
        color: $text-white;
        font-size: 1.3rem;
    }
}
.m-emailSubscribe__check{
    opacity: 0;
    position: absolute;
}
.m-emailSubscribe__checkbox{
    display: block;
    height: 14px;
    width: 14px;
    background: $bg-white;
    border-radius: $border-radius-primary;
    position: relative;
    flex-shrink: 0;
}
.m-emailSubscribe__check:checked + .m-emailSubscribe__checkbox{
    background: $bg-brand-secondary;

    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 5px;
        width: 4px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}
/* ========================================================================== */
/* FOOTER CONTACTS */
/* ========================================================================== */

.o-footerContacts{
    padding: 6rem 0;
    background: $bg-brand;
    position: relative;

    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0px;
        height: 12px;
        background-image: radial-gradient(circle at 50% 15%, $bg-white 50%, transparent 51%);
        background-position: center;
        background-size: 12px 12px;
    }
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 12px;
        background-image: radial-gradient(circle at 50% 15%, $bg-brand 50%, transparent 51%);
        background-position: center;
        background-size: 12px 12px;
    }
}

.o-footerContacts__title{
    font-size: 1.8rem;
    font-weight: 600;
    color: $text-white;
    margin-bottom: 3rem;
    margin-top: 0rem;
}
.o-footerContacts__wrap{
    display: flex;
    flex-direction: row;
}
.o-footerContacts__single{
    font-size: 1.6rem;
    font-weight: 400;
    color: $text-white;
    margin: 0rem 2rem 2rem 0;
    padding: 1.5rem 3rem;
    border: 1px solid rgba(255,255,255,0.1);
    background: $bg-brand-dark;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.o-footerContacts__icon{
    margin-right: 10px;
    height: 15px;
}
.o-footerContacts__Socialwrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}
.o-footerContacts__socialIcon{
    margin-right: 10px;
    height: 30px;
    width: 30px;
}
.o-footerContacts__socialName{
    font-size: 1.6rem;
    font-weight: 400;
    color: $text-white;
    margin: 0;
}
@media screen and (max-width:768px){
    .o-footerContacts{
        padding: 20px 0;
    }
    .o-footerContacts__col{
        padding: 0 10px 10px 10px;
    }

}

@media screen and (max-width:500px){
    .o-footerContacts__col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px 20px 10px;
    }
    .o-footerContacts__col:last-child{
        border-top: 1px solid rgba(255,255,255,0.1);
        padding: 20px 10px 0 10px;
    }
    .o-footerContacts__wrap{
        flex-direction: column;
        align-items: center;
    }
    .o-footerContacts__single{
        margin: 0;
        margin-top: 10px; 
    }
}

/* ========================================================================== */
/* FOOTER MAIN */
/* ========================================================================== */

.o-footerMain{
    padding-top: 2rem;
}
.o-footerMain__title{
    font-size: 1.8rem;
    font-weight: 400;
    color: $text-white;
    margin-bottom: 1rem;
    margin-top: 4rem;
    padding-bottom: 1rem;
    position: relative;
    display: inline-flex;

    &::after{
        content: "";
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 100%;
        height: 12px;
        background: rgba(255,255,255,0.2);
    }
}
.o-footerMain__link{
    color: $text-white;
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
    line-height: 3.6rem;
    opacity: 0.7;

    &:hover, &:active, &:focus{
        color: $text-white;
        text-decoration: underline;
        opacity: 1;
    }
}
.o-footerMain__text{
    color: $text-white;
    display: block;
    font-size: 1.4rem; 
    line-height: 3.6rem;
    margin: 0;
}
@media screen and (max-width:768px){

}

@media screen and (max-width:500px){
    .o-footerMain{
        padding: 0;
    }
    .o-footerMain__title{
        margin-top: 2rem;
        font-size: 16px;
    }
    .o-footerMain__row{
        margin: 0 -5px;
        .col-6{
            padding: 0 5px;
            padding-bottom: 20px;
        }
    }
}