.m-productDetailMain{

}
.m-productDetailMain__title{
    text-align: left;
    line-height: 1.25;
    margin-bottom: 3rem;
    color: $text-primary;

    &::after{
        content: none;
    }
}
.m-productDetailMain__misc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-productDetailMain__rating{

}
.m-productDetailMain__social{

}
.m-productDetailMain__tags{
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
}
.m-productDetailMain__tagSingle{
    @include f-14-dark-regular;
    color: $text-dark;
    margin: 0px;
}
.m-productDetailMain__description{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
}
.m-productDetailMain__descriptionTitle{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: $text-medium;
}
.m-productDetailMain__descriptionText{
    font-size: 1.4rem;
    color: $text-primary;
    line-height: 3rem;
    margin: 0;
}

.m-productDetailMain__sizes{
    margin-top: 4rem;
}

.m-productDetailMain__variations{
    background: $bg-light;
    padding: 20px;
    border-radius: $border-radius-primary;
    margin-top: 1rem;
}
.m-productDetailMain__priceWrap{
    flex-grow: 1;
    margin-top: 4rem;
}
.m-productDetailMain__cta{
    background: transparent;
    border-radius: $border-radius-primary;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0rem;
    flex-wrap: wrap;
    padding-bottom: 4rem;
    border-bottom: 1px solid $border-grey;
}
.m-productDetailMain__ctaTitle{
    width: 100%;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 2rem;
    color: $text-medium;
}
.m-productDetailMain__priceOld{
    @include f-14-dark-semibold;
    text-decoration: line-through;
}
.m-productDetailMain__priceMain{
    @include f-30-dark-semibold;
    color: $text-red;
    margin: 0;
    line-height: 1;
}
.m-productDetailMain__priceDPH{
    @include f-14-dark-regular;
    font-size: 1.3rem;
}
.m-productDetailMain__qty{
    padding-right: 2rem;
}
.m-productDetailMain__button{
    padding: 0 6rem;
    
    .m-productDetailMain__icon{
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

.m-productDetailMain__info{
    display: flex;
    flex-direction: column;
    /*border-left: 1px solid $border-grey;
    margin-left: 8rem;*/
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}
.m-productDetailMain__infoContent{

}
.m-productDetailMain__infoTitle{
    color: $text-medium;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}
.m-productDetailMain__infoNumber{
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;

    span{
        margin-right: 10px;
    }
}
.m-productDetailMain__stock{
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
}
.m-productDetailMain__stockText{
    @include f-14-dark-regular;
    color: $text-green;
    margin-left: 10px;
}
.m-productDetailMain__id{
    @include f-14-dark-regular;
    margin-bottom: 0.5rem;
}

.m-productDetailMain__parameters{
    padding: 4rem 0;
}
.m-productDetailMain__parametersTitle{
    color: $text-medium;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 400;
}
.m-productDetailMain__parametersWrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.m-productDetailMain__parametersSingle{
    padding: 15px 15px 15px 0;
    display: flex;
    align-items: center;
    flex-basis: 33.33%;
}
.m-productDetailMain__parametersImgWrap{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-productDetailMain__parametersImg{
    height: 50px;
}
.m-productDetailMain__parametersContent{
    padding-left: 2rem;
}
.m-productDetailMain__parametersName{
    color: $text-medium;
    font-size: 1.4rem;
    margin-bottom: 0;
    font-weight: 400;
}
.m-productDetailMain__parametersValue{
    color: $text-brand;
    font-size: 1.6rem;
}


.m-productDetailMain__icons{
    display: flex;
    flex-wrap: wrap;
    margin: 4rem -5px;
}
.m-productDetailMain__iconsSingle{
    min-width: 20%;
    padding: 5px;
}

@media screen and (max-width:991px){
    .m-productDetailMain{
        padding-top: 20px;
    }
    .m-productDetailMain__cta{
        flex-wrap: wrap;
    }
    .m-productDetailMain__title{
        font-size: 2.4rem;
    }
}

@media screen and (max-width:500px){
    .m-productDetailMain__priceWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .m-productDetailMain__qty{
        width: 100%;
        padding: 0;
        margin: 10px 0;
    }
    .m-productDetailMain__button{
        width: 100%
    }
    .m-productDetailMain__info{
        flex-direction: column;
    }
    .m-productDetailMain__id{
        margin-top: 5px;
    }
}