.o-productDetailVariants{
    background: $bg-white;
    padding: 0 0 4rem 0;
}
.o-productDetailVariants__row{
    margin-right: -5px;
    margin-left: -5px;
}
.o-productDetailVariants__col{
    padding: 5px;
}
.o-productDetailVariants__title{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: $text-medium;
}

.m-formGroup.o-productDetailVariants__single{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    border: 1px solid $border-grey;
    position: relative;
    background: $bg-white;
    margin: 0;
    transition: 0.2s ease border;

    &:hover, &:active, &:focus{
        border: 1px solid $border-brand;
    }
}
.o-productDetailVariants__inputWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 10px;
}
.o-productDetailVariants__label{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}
.o-productDetailVariants__input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.o-productDetailVariants__radiobutton{
    margin: 0;
}
.o-productDetailVariants__content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 10px;
    justify-content: center;
}
.o-productDetailVariants__imgWrap{
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.o-productDetailVariants__img{
    max-height: 100%;
}
.o-productDetailVariants__name{
    font-size: 1.6rem;
    font-weight: 700;
    color: $text-primary;
}
.o-productDetailVariants__price{
    font-size: 1.4rem;
    font-weight: 600;
    color: $text-brand;
}
.o-productDetailVariants__stock{
    font-size: 1.4rem;
    font-weight: 600;
    color: $text-green;
}

@media screen and (max-width:991px){
    .o-productDetailVariants{
        
    }
}