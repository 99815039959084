.o-sectionPoints{
    padding: 0rem 0 6rem 0;
}
.o-sectionPoints__container{
    display: flex;
    flex-direction: row;
}
.o-sectionPoints__wrap{
    background: $bg-light;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.o-sectionPoints__single{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    padding: 10px 0;
}
.o-sectionPoints__imgWrap{
    background: rgba(208,1,9,0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    border-radius: $border-radius-round;
}
.o-sectionPoints__img{
    width: 34px;
}
.o-sectionPoints__titleWrap{
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.o-sectionPoints__title, .o-sectionPoints__text{
    font-size: 16px;
    margin: 0;
    color: $text-primary;
}



@media screen and (max-width: 991px){
    .o-sectionPoints{
        display: none;
    }  
}