.o-cartDeliveryForm{
    margin: 0;
}
.o-cartDeliveryForm__title{
    @include f-24-primary-semibold;
    font-weight: 700;
    margin: 0;
    padding: 4rem 0 2rem 0;
}
.o-cartDeliveryForm__col{
    margin: 4rem 0;
}

.o-cartDeliveryForm__item{
    margin: 0;
    margin-bottom: -21px;

    & .m-formGroup.-radiobutton{
        padding: 3rem 20px;
    }
}
@media screen and (max-width: 768px){
    .o-cartDeliveryForm__title{
        text-align: center;
    }
}