main{
    background: $bg-white;
    padding: 6rem 0;
}

@media screen and (max-width:768px){
    main{
        background: $bg-white;
        padding: 20px 0;
    }
}
@media screen and (max-width:575px){
    main{
        padding: 10px 0;
    }
}