section{
    padding: 8rem 0;
}
section h1{
    font-size: 3rem;
    color: $text-brand;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;

    &::after{
        content: "";
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 12px;
        background: $bg-brand;
        opacity: 0.1;
    }
}
@media screen and (max-width:575px){
    section{
        padding: 2rem 0;
    }
    section h1{
        font-size: 20px;
        text-align: center;
        margin-bottom: 2rem;

        &::after{
            bottom: 0px;
        }
    }
}