.container{
    max-width: 1340px;
    padding-right: 20px;
    padding-left: 20px;
}
@media screen and (max-width:768px){
    .container{
        padding-right: 10px;
        padding-left: 10px;
    }
}

.container-full{
    max-width: 1920px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
}