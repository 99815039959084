.m-cartFreeShipping{
    background: rgba(37,158,27,0.1);
    height: 100%;
    min-height: 62px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-cartFreeShipping__progress{
    background: $bg-light;
}
.m-cartFreeShipping__progressBar{
    background: $bg-brand;
}
.m-cartFreeShipping__text{
    @include f-16-primary-regular;
    text-align: right;
    margin: 0;
    color: $text-green;
    font-weight: 600;
}
.m-cartFreeShipping__price{
    color: $text-green;
}

@media (max-width: 768px){
    .m-cartFreeShipping{
        width: 100%;
        margin-bottom: 20px;
    }
}