.o-cartNav{
    background: $bg-light;
    padding: 0;
}

.o-cartNav__single{
    background: transparent;
    position: relative;
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.o-cartNav__heading{
    display: flex;
    align-items: center;
    width: 100%;
}
.o-cartNav__title{
    @include f-18-primary-semibold;
    margin-bottom: 0px;
    margin-left: 10px;
}
.o-cartNav__number{
    @include f-14-primary-regular;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $bg-medium;
    color: $text-white;
    z-index: 2;
}
.o-cartNav__content{
    width: 100%;
    padding: 10px 20px;
    padding-left: 38px;
    position: relative;
    margin: 10px 0;

    &::before{
        content: "";
        position: absolute;
        left: 13px;
        width: 2px;
        height: 100%;
        top: 0;
        background: $bg-medium;
    }
}
.o-cartNav__text{
    font-size: 1.6rem;
    display: flex;
    line-height: 1.75;

    span{
        font-weight: 600;
        flex-grow: 1;
        text-align: right;
        white-space: nowrap;
    }
    &.-empty{
        color: $text-medium;
    }
    &.-small{
        font-size: 1.4rem;
        color: $text-medium;
        font-weight: 400;
    }
}

.o-cartNav__link{
    color: $text-medium;
    margin-top: 1rem;
    font-size: 1.4rem;
    display: flex;
}

.o-cartNav__single.-active{
    .o-cartNav__title{
        color: $text-primary;
    }
    .o-cartNav__number{
        color: $text-white;
        background: $bg-brand-secondary;
    }
    .o-cartNav__content::before{
        background: $bg-brand-secondary;
        
    }
}
.o-cartNav__single.-complete{
    .o-cartNav__title{
        color: $text-primary;
    }
    .o-cartNav__number{
        color: $text-white;
        background: $bg-brand-secondary;
    }
    .o-cartNav__content::before{
        background: $bg-brand-secondary;
    }
}



@media screen and (max-width: 991px){
    .o-cartNav{
        display: none;
    }
}