.o-navTop{
    border-bottom: 1px solid rgba(255,255,255,0.15);
    background: $bg-brand;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 40px;
}
.o-navTop__link{
    opacity: 0.8;
    color: $text-white;
    font-weight: 400;
    font-size: 1.4rem;
    transition: 0.2s ease opacity;
    padding: 0 3rem 0 0;

    &:hover, &:active, &:focus{
        opacity: 1;
        text-decoration: none;
        color: $text-white;
    }
}
.o-navTop__content{
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}
.o-navTop__text{
    opacity: 0.8;
    color: $text-white;
    font-weight: 400;
    font-size: 1.4rem;
}
.o-navTop__contact{
    display: flex;
    padding-left: 3rem;
    opacity: 0.8;
    color: $text-white;
    font-weight: 400;
    font-size: 1.4rem;

    span{
        margin-right: 10px;
        width: 16px; 
    }
}

.o-navMain{
    background: $bg-brand;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    z-index: 5;
    position: relative;
    margin: 0 auto;

    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 12px;
        background-image: radial-gradient(circle at 50% 15%, $bg-brand 50%, transparent 51%);
        background-position: center;
        background-size: 12px 12px;
    }
    .container-full{
        height: 100%;
        justify-content: center;
        align-items: center;
    }

}
@media screen and (max-width:991px){
    .o-navTop{
        display: none;
    }
    .o-navMain{
        height: 56px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6;
        box-shadow: $box-shadow-primary;
        
        .container-full{
            padding: 0;
        }
    }

}
