.o-sectionNews{
    background: $bg-light;
    position: relative;
}
.o-sectionNews__title{
}
.o-sectionNews__btnWrap{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}