@font-face {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/PublicSans-Regular.woff2") format("woff2"),
         url("../fonts/PublicSans-Regular.woff") format("woff");
}
@font-face {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/PublicSans-SemiBold.woff2") format("woff2"),
         url("../fonts/PublicSans-SemiBold.woff") format("woff");
}
@font-face {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/PublicSans-Bold.woff2") format("woff2"),
         url("../fonts/PublicSans-Bold.woff") format("woff");
}

$font-family-primary: "Public Sans", sans-serif;
$font-family-secondary: "Public Sans", sans-serif;
$font-family-system: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
