.m-cartUserItem{
    border-radius: $border-radius-primary;
    position: relative;
    height: 100%;
    background: $bg-white;
    border: 1px solid $border-grey;
    padding: 3rem;
    margin-top: -1px;
}
.m-cartUserItem__top{
    padding-bottom: 4rem;
}
.m-cartUserItem_title{
    @include f-18-primary-semibold;
    line-height: 1;
    text-align: left;;
    margin-bottom: 0px;
}
.m-cartUserItem_subtitleWrap{
    display: flex;
    flex-direction: row;
}
.m-cartUserItem__bottom{
    background: $bg-white;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.m-cartUserItem__small{
    font-size: 1.4rem;
    color: $text-medium;
    line-height: 1;
    padding-bottom: 7px;
}
.m-cartUserItem__text{
    @include f-16-primary-semibold;
    line-height: 1;
}
.m-cartUserItem__row{
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    flex-basis: 33.333%;
}
.m-cartUserItem__single{
    flex-grow: 1;   
    padding-right: 10px;
    width: 50%;
}

@media screen and (max-width: 991px){
    .m-cartUserItem__row{
        flex-basis: 50%;
        width: 50%;
    } 
}
@media screen and (max-width: 500px){
    .m-cartUserItem__row{
        flex-basis: 100%;
        width: 100%;
    } 
}